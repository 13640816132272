import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/profile";

  const username = localStorage.getItem('email')
  const permissions = localStorage.getItem('permission')

  const [user, setUser] = useState({
    username: username,
    permissions: permissions
  });


  const login = (user) => {
    const permissions = ['admin','customer'];

    localStorage.setItem('token', user.token);
    // localStorage.setItem('refreshToken', user.refreshToken);
    localStorage.setItem('email', user.email);
    localStorage.setItem('permission', permissions[user.permission]);
    const sourcePath = localStorage.getItem('redirection') || '/';
    localStorage.removeItem('redirection'); // Clear the stored source path

 
    setUser({ username: user.email, permissions: permissions[user.permission] });
    if (permissions[user.permission] === "admin") {
      navigate('/admin', { replace: true });
    } else if (permissions[user.permission] === "customer") {
      navigate('/customer', { replace: true });
    } else {
      // Redirect the user back to the source path
    navigate(sourcePath);
      //navigate(redirectPath, { replace: true });
    }
  };

  const logout = () => {
    setUser({ username: "", permissions: [] });
    localStorage.removeItem('token');
    // localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};
