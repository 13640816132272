import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const Success = React.lazy(() => import("pages/success"));
const About = React.lazy(() => import("pages/about"));
const Contact = React.lazy(() => import("pages/contact"));
const Login = React.lazy(() => import("pages/login"));
const Register = React.lazy(() => import("pages/register"));
const QRScanner = React.lazy(() => import("pages/qr_scanner"));
const NotFound = React.lazy(() => import("pages/notfound"));
const TermsOfService = React.lazy(() => import("pages/termsofservice"));
const RefundPolicy = React.lazy(() => import("pages/refundpolicy"));
const NoRefundPolicy = React.lazy(() => import("pages/norefundpolicy"));

const AdminUsers = React.lazy(() => import("pages/admin/users"));
const AdminReport = React.lazy(() => import("pages/admin/reports"));

const CustomerProfile = React.lazy(() => import("pages/customer/profile"));
const CustomerSecurity = React.lazy(() => import("pages/customer/security"));
const CustomerQRCode = React.lazy(() => import("pages/customer/qrcodes"));
const CustomerReport = React.lazy(() => import("pages/customer/reports"));

const routes = [
  { path: "/", exact: true, name: "Home", element: Home },
  { path: "/login", name: "Login", element: Login },
  { path: "/register", name: "Register", element: Register },
  { path: "/about", name: "About", element: About },
  { path: "/contact", name: "Contact", element: Contact },
  { path: "/success", name: "Success", element: Success },
  { path: "/qr_scanner", name: "QRScanner", element: QRScanner },
  { path: "/terms-of-service", name: "Terms Of Service", element: TermsOfService },
  { path: "/refund-policy", name: "Refund Policy", element: RefundPolicy },
  { path: "/no-refund", name: "No Refund Policy", element: NoRefundPolicy },
  { path: "/*", name: "NotFound", element: NotFound },
];

const authRoutes = [
]

const customerRoutes = [
  { path: "/profile", name: "Profile", element: CustomerProfile },
  { path: "/qrcodes", name: "QRCode", element: CustomerQRCode },
  { path: "/report", name: "Report", element: CustomerReport },
  { path: "/security", name: "Security", element: CustomerSecurity },
];

const adminRoutes = [
  { path: "/report", name: "Dashobard", element: AdminReport },
  { path: "/users", name: "Dashobard", element: AdminUsers },
]

export default routes;
export { authRoutes, adminRoutes, customerRoutes };