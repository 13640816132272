import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";

import { AdminLayout, CustomerLayout } from 'layout';

import routes from "routes";

import Authentication from "./authentication";
import Authorization from "./authorization";
import PERMISSIONS from "permissions/permissions";

import 'assets/css/main.scss'

const RoutePath = () => {

  console.log('routepath');
  
  return (
    <ConfirmProvider>
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route
            element={<Authorization permissions={[PERMISSIONS.ADMIN]} />}
          >
            <Route path="/admin/*" element={<AdminLayout />} />
          </Route>
          <Route
            element={<Authorization permissions={[PERMISSIONS.CUSTOMER]} />}
          >
            <Route path="/customer/*" element={<CustomerLayout />} />
          </Route>
        </Routes>
      </Suspense>
    </ConfirmProvider>
  );
};

export default RoutePath;
