import React from "react";
  import { Navigate, useLocation, Outlet } from "react-router-dom";

document.title = 'QrCode | UnAuthorize';

const Unauthorized = () => {
  const location = useLocation();

  // const navigate = useNavigate() 
  return <Navigate to='/' state={{ path: location.pathname }} replace />;
  // return <h1 className="text-center text-2xl sm:text-3xl lg:text-6xl font-poppins mt-6">You don't have permission to view this page</h1>;
};

export default Unauthorized;
