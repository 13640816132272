// axiosConfig.js
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;

  // If the error status is 401 and there is no originalRequest._retry flag,
  // it means the token has expired and we need to refresh it
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      // const refreshToken = localStorage.getItem('refreshToken');
      // const response = await axios.post('/user/refresh-token', { refreshToken });
      // const { user } = response.data;
      // localStorage.setItem('token',user.token);
      // localStorage.setItem('email',user.email);
      // localStorage.setItem('role',user.role);
      // // Retry the original request with the new token
      // originalRequest.headers.Authorization = `Bearer ${user.token}`;
      // return axios(originalRequest);
    } catch (error) {
      // Handle refresh token error or redirect to login
    }
  }
  return Promise.reject(error);
});


export default api;
